<template>
    <div v-if="visible" style="height:100vh; width:100%; position:absolute" class="dF jE">
        <div style="height:100vh; background-color:#FFF; width:550px; padding:25px 30px; border-left:1px solid #707070; overflow-y:scroll">
            <h5>Available Homes</h5>
            <div class="dF aC py-3" v-for="unit in units" :key="unit" :value="unit" style="border-bottom:1px solid #E4E7EB">
                <div class="mr-3">
                    <img style="object-fit:cover; max-width:150px" :src="storeUnits[unit].image" />
                </div>
                <div class="f1">
                    <div class="dF aC">
                        <div class="mr-2" style="color:#9EA0A5;">Model Type</div>
                        <div>{{storeUnits[unit].unitGroup.type}}</div>
                    </div>
                    <div class="dF jSB">
                        <div class="text-dark">{{`${storeUnits[unit].unitGroup.name} ${storeUnits[unit].name}`}}</div>
                        <div>{{`Price: $ ${getPrice(unit)}`}}</div>
                    </div>
                    <div class="dF aC">
                        <div style="font-size:12px" v-if="getBeds(unit) != ''">{{`${getBeds(unit)} Beds`}}</div>&nbsp;<div style="font-size:12px" v-if="getBeds(unit) != ''">|</div>&nbsp;
                        <div style="font-size:12px" v-if="getBaths(unit) != ''">{{`${getBaths(unit)} Baths`}}</div>&nbsp;<div style="font-size:12px" v-if="getBaths(unit) != ''">|</div>&nbsp;
                        <div style="font-size:12px" v-if="getGarages(unit) != ''">{{`${getGarages(unit)} Garages`}}</div>&nbsp;<div style="font-size:12px" v-if="getGarages(unit) != ''">|</div>&nbsp;
                        <div style="font-size:12px" v-if="getStories(unit) != ''">{{`${getStories(unit)} Stories`}}</div>&nbsp;<div style="font-size:12px" v-if="getStories(unit) != ''">|</div>&nbsp;
                        <div style="font-size:12px" v-if="getSqfts(unit) != ''">{{`${getSqfts(unit)} Sq. Ft.`}}</div>
                    </div>
                    <div @click="$store.commit('OPEN_UNIT_DETAILS', unit)" style="background-color:var(--orange); cursor:pointer; border-radius:4px; color:#FFF; display:inline-block" class="py-1 px-4">VIEW</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed:{
        visible() {
            return this.$store.state.inventory.detailModal.visible
        },
        storeUnits() {
            return this.$store.state.inventory.units
        },
        units() {
            return this.$store.state.inventory.detailModal.units
        }
    },
    methods:{
        getPrice(id) {
            let prices = this.storeUnits[id].packages.map(x => x.price)
            let min = Math.min(...prices)
            let max = Math.max(...prices)
            if (min == max && min == 0) return 'N/A'
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        },
        getBeds(id) {
            let beds = this.storeUnits[id].packages.map(x => x.beds)
            let min = Math.min(...beds)
            let max = Math.max(...beds)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        },
        getBaths(id) {
            let baths = this.storeUnits[id].packages.map(x => x.baths)
            let min = Math.min(...baths)
            let max = Math.max(...baths)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        },
        getGarages(id) {
            let garages = this.storeUnits[id].packages.map(x => x.garages)
            let min = Math.min(...garages)
            let max = Math.max(...garages)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        },
        getSqfts(id) {
            let sqfts = this.storeUnits[id].packages.map(x => x.sqft)
            let min = Math.min(...sqfts)
            let max = Math.max(...sqfts)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        },
        getStories(id) {
            let stories = this.storeUnits[id].packages.map(x => x.stories)
            let min = Math.min(...stories)
            let max = Math.max(...stories)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        }
    }
}
</script>

<style>

</style>