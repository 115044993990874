var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-drawer',{attrs:{"width":'550px',"visible":_vm.visible},on:{"close":_vm.onClose}},[_c('div',{staticClass:"dF aC"},[_c('a-icon',{staticClass:"mr-4",staticStyle:{"font-size":"1.75rem","cursor":"pointer"},attrs:{"type":"arrow-left"},on:{"click":_vm.onClose}}),_c('h5',{staticStyle:{"margin-bottom":"0"}},[_vm._v("Update Status")])],1),_c('div',{style:({
                position: 'absolute',
                left: 0,
                bottom:0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            })},[_c('div',{staticClass:"dF jE"},[_c('a-button',{staticClass:"cancel-button mr-3",attrs:{"size":"large"},on:{"click":_vm.onClose}},[_vm._v("CANCEL")]),_c('a-button',{attrs:{"type":"primary","size":"large"}},[_vm._v("UPDATE")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }