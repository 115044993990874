<template>
    <div v-if="visible">
        <UpdateStatus />
        <a-drawer :width="'550px'" :visible="visible" @close="onClose">
            <div class="dF aC">
                <a-icon @click="onClose" class="mr-4" style="font-size:1.75rem; cursor:pointer" type="arrow-left" />           
                <h5 class="mr-5" style="margin-bottom:0">{{`${storeUnits[unit].unitGroup && storeUnits[unit].unitGroup.name ? storeUnits[unit].unitGroup.name:''} ${storeUnits[unit].name}`}}</h5>
                <div class="mr-2" style="color:#9EA0A5">Model Type</div>
                <div>{{storeUnits[unit].unitGroup && storeUnits[unit].unitGroup.type ? storeUnits[unit].unitGroup.type : ''}}</div>
            </div>
            <div class="mt-5">
                <img :src="storeUnits[unit].image" style="width:100%; object-fit:cover" />
            </div>
            <div class="dF aC mt-3">
                <div v-if="getBeds(unit) != ''">{{`${getBeds(unit)} Beds`}}</div>&emsp;<div v-if="getBeds(unit) != ''">|</div>&emsp;
                <div v-if="getBaths(unit) != ''">{{`${getBaths(unit)} Baths`}}</div>&emsp;<div v-if="getBaths(unit) != ''">|</div>&emsp;
                <div v-if="getGarages(unit) != ''">{{`${getGarages(unit)} Garages`}}</div>&emsp;<div v-if="getGarages(unit) != ''">|</div>&emsp;
                <div v-if="getStories(unit) != ''">{{`${getStories(unit)} Stories`}}</div>&emsp;<div v-if="getStories(unit) != ''">|</div>&emsp;
                <div v-if="getSqfts(unit) != ''">{{`${getSqfts(unit)} Sq. Ft.`}}</div>
            </div>
            <hr />
            <h6>Floorplan Package</h6>
            <div class="w-full dF">
                <div style="width:50%; padding-right:10px; border-right:1px solid #707070">
                    <div @click="selectedPackage = packI" :style="packI == 0 ? '':'margin-top:10px'" class="py-1 px-3" style="display:inline-block" v-for="(pack, packI) in storeUnits[unit].packages" :key="pack.id" :class="selectedPackage == packI ? 'selected-package':'nonselected-package'">
                        {{pack.name}}
                    </div>
                </div>
                <div style="width:50%; padding-left:10px">
                    <div class="dF jSB">
                        <div style="color:#40454C">Elevation Price</div>
                        <div style="color:#40454C">{{`$ ${getElevPrice()}`}}</div>
                    </div>
                    <div class="dF jSB">
                        <div style="color:#40454C">Lot Condition</div>
                        <!-- <div style="color:#40454C">{{`$ ${getCondPrice()}`}}</div> -->
                    </div>
                    <div class="dF jSB">
                        <div style="color:#40454C">Lot Premium</div>
                    </div>
                </div>
            </div>
            <div
                :style="{
                    position: 'absolute',
                    left: 0,
                    bottom:0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }"
                >
                <div class="dF jE">
                    <a-button @click="$store.commit('OPEN_UPDATE_STATUS')" class="secondary-button mr-3" size="large"><i class="fa fa-tag mr-2" />UPDATE STATUS</a-button>
                    <a-button type="primary" size="large"><div class="dF aC"><svg class="mr-2" fill="#FFF" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512">\<path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"/></svg>MAKE A TRANSACTION</div></a-button>
                </div>
            
            </div>
        </a-drawer>
    </div>
</template>

<script>
import UpdateStatus from '@/components/inventory/UpdateStatus'
export default {
    components:{
        UpdateStatus
    },
    data() {
        return {
            selectedPackage:0
        }
    },
    computed:{
        visible() {
            return this.$store.state.inventory.unitDetails.visible
        },
        unit() {
            return this.$store.state.inventory.unitDetails.unit
        },
        storeUnits() {
            return this.$store.state.inventory.units
        }
    },
    methods:{
        getElevPrice() {
            return this.storeUnits[this.unit].packages[this.selectedPackage].price
        },

        onClose() {
            this.$store.commit('CLOSE_UNIT_DETAILS')
        },
        getBeds(id) {
            let beds = this.storeUnits[id].packages.map(x => x.beds)
            let min = Math.min(...beds)
            let max = Math.max(...beds)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        },
        getBaths(id) {
            let baths = this.storeUnits[id].packages.map(x => x.baths)
            let min = Math.min(...baths)
            let max = Math.max(...baths)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        },
        getGarages(id) {
            let garages = this.storeUnits[id].packages.map(x => x.garages)
            let min = Math.min(...garages)
            let max = Math.max(...garages)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        },
        getSqfts(id) {
            let sqfts = this.storeUnits[id].packages.map(x => x.sqft)
            let min = Math.min(...sqfts)
            let max = Math.max(...sqfts)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        },
        getStories(id) {
            let stories = this.storeUnits[id].packages.map(x => x.stories)
            let min = Math.min(...stories)
            let max = Math.max(...stories)
            if (min == max && min == 0) return ''
            else if (min == max && min != 0) return `${max}`
            else return `${min} - ${max}`
        }
    }
}
</script>

<style scoped>
.selected-package{
    background-color:#FFF;
    border-radius:4px;
    border:1px solid var(--orange);
    cursor:pointer;
    color:var(--orange);
}
.nonselected-package{
    background-color:#F2F2F1;
    border-radius:4px;
    border:1px solid #D0C9D6;
    cursor:pointer;
}
</style>
<style>
.secondary-button.ant-btn.ant-btn-lg{
    border:1px solid var(--orange);
    color:var(--orange);
}
.secondary-button.ant-btn.ant-btn-lg:hover{
    color:#FFF;
    background-color:var(--orange);
}
</style>