<template>
    <div class="w-full">
        <div
            v-if="visible"
            style="
                position: absolute;
                width: 100%;
                height: 100vh;
                top: 0;
                left: 0;
                z-index: 1000;
            "
        >
            <AvailableUnits />
            <LotDetails />
            <UnitDetails />
        </div>
        <div
            class="w-full pb-4 px-4 h-full"
            style="display: flex; flex-direction: column"
        >
            <div class="dF jE" style="padding: 15px 0">
                <div
                    @click="$store.commit('CHANGE_VIEW', 'map')"
                    style="cursor: pointer"
                    class="mr-3"
                >
                    <svg
                        :style="
                            displayView == 'map'
                                ? 'fill:var(--orange)'
                                : 'fill:#D3D0D6'
                        "
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                    >
                        <path
                            d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z"
                        />
                    </svg>
                </div>
                <div
                    @click="$store.commit('CHANGE_VIEW', 'list')"
                    style="cursor: pointer"
                >
                    <svg
                        :style="
                            displayView == 'list'
                                ? 'fill:var(--orange)'
                                : 'fill:#D3D0D6'
                        "
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            d="M149.333 216v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-80c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zM125.333 32H24C10.745 32 0 42.745 0 56v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zm80 448H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm-24-424v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24zm24 264H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24z"
                        />
                    </svg>
                </div>
            </div>
            <div id="siteplan-id" class="dF aC jC w-full h-full">
                <div
                    class="
                        siteplan-holder
                        brdrds2
                        overflow-hidden
                        w-full
                        h-full
                        relative
                    "
                    ref="siteplan"
                >
                    <div
                        class="
                            absolute
                            right-0
                            bottom-0
                            w-full
                            flex
                            justify-end
                            px-4
                            pb-4
                        "
                    >
                        <svg
                            width="49.001"
                            height="48.999"
                            viewBox="0 0 49.001 48.999"
                            class="mr-3 zoomOut"
                            @click="zoom('out')"
                        >
                            <path
                                d="M-7588-2665.5h-42a3.5,3.5,0,0,1-3.5-3.5v-42a3.5,3.5,0,0,1,3.5-3.5h42a3.5,3.5,0,0,1,3.5,3.5v42A3.5,3.5,0,0,1-7588-2665.5Zm-30.591-26.865h0a1.661,1.661,0,0,0-1.659,1.659v1.661a1.662,1.662,0,0,0,1.659,1.661h19.93a1.663,1.663,0,0,0,1.661-1.661v-1.661a1.662,1.662,0,0,0-1.661-1.659c-.061.05-6.96.112-12.512.112C-7613.835-2692.253-7618.34-2692.268-7618.59-2692.365Z"
                                transform="translate(7633.5 2714.5)"
                                fill="#F7941E"
                                opacity="0.812"
                            />
                        </svg>
                        <svg
                            width="49.001"
                            height="48.999"
                            viewBox="0 0 49.001 48.999"
                            class="zoomIn"
                            @click="zoom('in')"
                        >
                            <path
                                d="M-7588-2665.5h-42a3.5,3.5,0,0,1-3.5-3.5v-42a3.5,3.5,0,0,1,3.5-3.5h42a3.5,3.5,0,0,1,3.5,3.5v42A3.5,3.5,0,0,1-7588-2665.5Zm-31.091-26.865a1.661,1.661,0,0,0-1.659,1.659v1.661a1.662,1.662,0,0,0,1.659,1.661h7.474v7.473a1.663,1.663,0,0,0,1.661,1.661h1.661a1.663,1.663,0,0,0,1.661-1.661v-7.473h7.473a1.663,1.663,0,0,0,1.661-1.661v-1.661a1.662,1.662,0,0,0-1.661-1.659h-7.473v-7.473a1.663,1.663,0,0,0-1.661-1.661h-1.661a1.663,1.663,0,0,0-1.661,1.661v7.473Z"
                                transform="translate(7633.5 2714.5)"
                                fill="#F7941E"
                                opacity="0.812"
                            />
                        </svg>
                    </div>
                    <canvas
                        class="w-full h-full siteplan-holder-canvas"
                    ></canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import initSp from "../../../public/resources/js/siteplan.js";
import LotDetails from "@/components/inventory/LotDetails";
import AvailableUnits from "@/components/inventory/AvailableUnits";
import UnitDetails from "@/components/inventory/UnitDetails";
export default {
    components: {
        LotDetails,
        AvailableUnits,
        UnitDetails,
    },
    computed: {
        visible() {
            return this.$store.state.inventory.detailModal.visible;
        },
        displayView() {
            return this.$store.state.inventory.displayView;
        },
        storeLots() {
            return this.$store.state.inventory.lots;
        },
    },
    data() {
        return {
            showing: {
                filter: false,
                plan: "",
                lot: "",
            },
            showingLot: {},
        };
    },
    methods: {
        zoom(is) {
            if (!is) return;
            if (is === "out") {
                let zoom = this.mysp.camera.zoom - 0.4;
                if (zoom <= 1.04) this.mysp.zoomReset();
                else this.mysp.zoomOut();
            }
            if (is === "in") this.mysp.zoomIn();
        },
        getUnits(id) {
            let lot = JSON.parse(JSON.stringify(this.storeLots[id]));

            this.$store.commit("LOAD", true);
            this.$api
                .get(`/architectural-controls/:instance/availableunits/${id}`)
                .then(({ data }) => {

                    data.units = data.units.map((x) => {
                        if (typeof x === "object" && x.id) return x.id;
                        return x;
                    });
                    data.attachedUnit =
                        data.attachedUnit && data.attachedUnit.id
                            ? data.attachedUnit.id
                            : data.attachedUnit || "";
                    data.siteplan = data.siteplan.id
                        ? data.siteplan.id
                        : data.siteplan;

                    if (lot.status !== data.status) {
                        this.$store.commit("SET_PROP", {
                            where: ["lots", data.id],
                            what: data,
                        });

                        let message = `Lot ${data.name} has been updated externally!`;
                        if (data.status === "sold")
                            message = `Lot ${data.name} has been sold already!`;

                        return this.$notification.error({
                            message,
                            description:
                                "This lot was externally updated by another user. Please review and try again.",
                        });
                    }

                    lot = JSON.parse(JSON.stringify(data));
                    this.$store.commit("OPEN_DETAILS", {
                        lot: this.storeLots[id],
                        units: data.units,
                    });
                })
                .catch((err) => {
                    this.$message.error("An error occurred. Please try again");
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
        onEvent(action, data) {
            if (action === "SHOW_LOT" && data && data.id) {
                this.showing.lot = data.id;
                this.showingLot = data;
                this.getUnits(data.id);

                return;
            } else if (action === "TAP") {
                this.showing.lot = "";
            }
        },
    },
    mounted() {
        let dom = this.$el.querySelector(".siteplan-holder");
        this.mysp = initSp({
            dom,
            spData: this.$store.state.inventory.siteplanMap,
            initCallback: this.initCallback,
            onEvent: this.onEvent,
            hideHold:
                (this.$store.state.options &&
                    this.$store.state.options["page-siteplan"] &&
                    this.$store.state.options["page-siteplan"].hideHold) ||
                false,
            hideAvailable: false,
        });

        let initObj = {
            instance: this.$store.state.instance.id,
            masterTab: true,
            socket: true,
            pulsateDots: false,
            hideDots: false,
        };
        this.mysp.init(initObj);
        window.mysp = this.mysp;
    },
    beforeUnmount() {
        if (this.mysp && this.mysp.ctx && this.mysp.canvas) {
            this.mysp.ctx.clearRect(0, 0, this.mysp.canvas, this.mysp.canvas);
        }
    },
};
</script>

<style lang="scss">
.ant-layout-content {
    position: static !important;
}
</style>
