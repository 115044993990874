<template>
    <div>
        <a-drawer @close="onClose" :width="'550px'" :visible="visible">
            <div class="dF aC">
                <a-icon @click="onClose" class="mr-4" style="font-size:1.75rem; cursor:pointer" type="arrow-left" />           
                <h5 style="margin-bottom:0">Update Status</h5>
            </div>
            <div
                :style="{
                    position: 'absolute',
                    left: 0,
                    bottom:0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }"
                >
                <div class="dF jE">
                    <a-button @click="onClose" class="cancel-button mr-3" size="large">CANCEL</a-button>
                    <a-button type="primary" size="large">UPDATE</a-button>
                </div>
            
            </div>
        </a-drawer>
    </div>
</template>

<script>
export default {
    computed:{
        visible() {
            return this.$store.state.inventory.updateStatus.visible
        }
    },
    methods:{
        onClose() {
            this.$store.commit('CLOSE_UPDATE_STATUS')
        }
    }
}
</script>

<style>
.cancel-button.ant-btn {
  border-color:#ECE9F1 !important;
  background-color:#ECE9F1; 
  color:#3F3356
}
</style>