<template>
    <div v-if="visible" style="bottom:0; width:calc(100% - 550px); height: 100%; bottom:0; position:absolute; display: flex; flex-direction: column;">
        <div @click="$store.commit('CLOSE_DETAILS')" class="f1" style="background-color: rgba(0,0,0,0.5);">
        </div>
        <div style="background-color:#FFF; padding:15px 25px">
            <div class="dF jSB">
                <div class="dark-text" style="font-size:20px">{{`Lot ${lot.name}`}}</div>
                <div class="dF aC">
                    <div class="dF aC mr-3">
                        <div class="mr-2" style="color:#9EA0A5">Tag(s)</div>
                        <a-input style="width:300px" />
                    </div>
                    <div class="py-1 px-5" style="border-radius:4px; color:#FFF" :style="lot.status == 'available' ? ' background-color:#00C48C': lot.status == 'hold' ? 'background-color:#FECE5B' : lot.status == 'conditional' ? 'background-color:#9693E8' : lot.status == 'sold' ? 'background-color:#FD6C83': lot.status == 'inventory' ? 'background-color:#2B93C6':'' ">{{lot.status.toUpperCase()}}</div>
                </div>
            </div>
            <div class="display-info mt-3">
                <div>
                    <div style="color:#9EA0A5">Lot Frontage</div>
                    <div>{{`${getFrontage()}'`}}</div>
                </div>
                <div>
                    <div style="color:#9EA0A5">Lot Condition</div>
                </div>
                <div>
                    <div style="color:#9EA0A5">Lot Premium</div>
                </div>
                <div>
                    <div style="color:#9EA0A5">Block #</div>
                    <div>{{lot.block && lot.block != '' ? lot.block : 'N/A'}}</div>
                </div>
            </div>
            <div class="display-info-2 mt-3">
                <div>
                    <div style="color:#9EA0A5">Address</div>
                    <div>{{`${lot.streetNumber && lot.streetNumber != '' ? lot.streetNumber : ''} ${lot.street && lot.street != '' ? lot.street:''}`}}</div>
                </div>
                <div>
                    <div style="color:#9EA0A5">Lot Gradings</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed:{
        lot() {
            return this.$store.state.inventory.detailModal.lot
        },
        units() {
            return this.$store.state.inventory.units
        },
        visible() {
            return this.$store.state.inventory.detailModal.visible
        }
    },
    data() {
        return {

        }
    },
    methods:{
        getFrontage() {
            let frontage = []
            if (!this.lot.units.length) {
                return 'N/A'
            } else {
                this.lot.units.forEach(unit => {
                    let id = ''
                    if (typeof unit == 'string') id = unit
                    else if (typeof unit == 'object') id = unit.id
                    if(this.units[id] && !frontage.includes(this.units[id].unitGroup.size)) {
                        frontage.push(this.units[id].unitGroup.size) 
                    }
                })
                if (frontage.length == 1) {
                    return frontage[0]
                } else if (frontage.length > 1) {
                    let min = Math.min(...frontage)
                    let max = Math.max(...frontage)
                    return `${min}-${max}`
                }
            }
        },
    }
}
</script>

<style scoped>
.display-info{
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2.5rem;
    display:grid;
}
.display-info-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2.5rem;
    display:grid;
}

</style>